import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"

import ListingCard from "../ListingCard"
import generateSlug from "../../utils/generateSlug"

const UnstyledList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`

const UnstyledListItem = styled.li``

const ListingsList = ({ title, listings }) => (
  <>
    {title && (
      <h3>{title}</h3>
    )}
    <UnstyledList>
      {listings.map(listing => {
        const slug = generateSlug(listing.id, listing.listingHemnetUrl)
        const href = `/bostad/${slug}`
        return (
          <UnstyledListItem key={listing.id}>
            <ListingCard href={href} listing={listing} />
          </UnstyledListItem>
        )
      })}
    </UnstyledList>
  </>
)

ListingsList.propTypes = {
  title: PropTypes.string,
  listings: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      streetAddress: PropTypes.string,
      listingHemnetUrl: PropTypes.string.isRequired,
      askingPrice: PropTypes.exact({
        amount: PropTypes.number.isRequired,
      }),
      fee: PropTypes.exact({
        amount: PropTypes.number.isRequired,
      }),
      tenure: PropTypes.exact({
        name: PropTypes.string.isRequired,
      }),
      housingForm: PropTypes.exact({
        name: PropTypes.string.isRequired,
      }),
    })
  ),
}

export default ListingsList
