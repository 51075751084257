const generateSlug = (id, listingHemnetUrl) => {
  if (listingHemnetUrl) {
    const slugTextId = listingHemnetUrl.replace(
      "https://www.hemnet.se/bostad/",
      ""
    )

    return slugTextId || id
  } else {
    return id
  }
}

module.exports = generateSlug
