import React from "react"
import styled from "@emotion/styled"

const ListingCardImageContainer = styled.div`
  display: flex;
  flex: 2;
  float: none;
  width: auto;
  height: auto;
  margin-right: 0;
  position: relative;

  @media (min-width: 670px) {
    flex: 1;
    display: block;
    flex: unset;
    width: 250px;
    height: 187.5px;
  }

  @media (min-width: 800px) {
    width: 336px;
    height: 252px;
  }

  @media (min-width: 990px) {
    width: 250px;
    height: 187.5px;
  }

  @media (min-width: 1320px) {
    width: 336px;
    height: 252px;
  }
`

const ListingImage = styled.img`
  display: flex;
  flex: 1;
  height: 150px;
  object-fit: cover;

  @media (min-width: 670px) {
    width: 100%;
    height: 100%;
  }
`

const NoListingImage = styled.div`
  flex: 1;
  height: 150px;
  position: relative;

  &::before {
    content: "Bild saknas";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: italic;
  }

  @media (min-width: 670px) {
    width: 100%;
    height: 100%;
  }
`

const ListingCardImage = ({ listing }) => {
  const { url, originalHeight, originalWidth } = listing.thumbnail || {
    url: null,
    originalHeight: null,
    originalWidth: null,
  }
  return (
    <ListingCardImageContainer>
      {url && (
        <ListingImage
          src={url}
          height={originalHeight}
          width={originalWidth}
          alt={`Bild på ${listing.streetAddress || "bostaden"}`}
        />
      )}
      {!url && <NoListingImage />}
    </ListingCardImageContainer>
  )
}

export default ListingCardImage
