import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import styled from "@emotion/styled"

import ListingCardImage from "./ListingCardImage"
import ListingCardInfo from "./ListingCardInfo"
import hemneoColors from "../../utils/hemneoColors"

const ListingLink = styled(Link)`
  display: flex;
  flex-direction: column;
  color: ${hemneoColors.black};
  text-decoration: none;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  margin: 0 8px 8px;

  @media (min-width: 550px) {
    margin-bottom: $baseUnit;
  }

  @media (min-width: 670px) {
    flex-direction: row;
    border: 1px solid ${hemneoColors.B2};
    height: 189.5px;
  }

  @media (min-width: 800px) {
    height: auto;
  }

  @media (min-width: 990px) {
    box-shadow: none;
    margin: 0 0 8px;
    height: 189.5px;
  }

  @media (min-width: 1320px) {
    height: auto;
  }

  &:hover,
  &:focus {
    border-color: ${hemneoColors.B1};
    text-decoration: none;
    color: $hemnetBlack;
  }
`

const ListingCard = ({ href, listing }) => (
  <ListingLink to={href}>
    <ListingCardImage listing={listing} />
    <ListingCardInfo listing={listing} />
  </ListingLink>
)

ListingCard.propTypes = {
  href: PropTypes.string.isRequired,
  listing: PropTypes.shape({
    id: PropTypes.string.isRequired,
    streetAddress: PropTypes.string,
    listingHemnetUrl: PropTypes.string.isRequired,
    askingPrice: PropTypes.exact({
      amount: PropTypes.number.isRequired,
    }),
    fee: PropTypes.exact({
      amount: PropTypes.number.isRequired,
    }),
    tenure: PropTypes.exact({
      name: PropTypes.string.isRequired,
    }),
    housingForm: PropTypes.exact({
      name: PropTypes.string.isRequired,
    }),
  }),
}

export default ListingCard
