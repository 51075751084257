import React from "react"
import styled from "@emotion/styled"

import hemneoColors from '../../utils/hemneoColors';

const ListingCardInfoContainer = styled.div`
  padding: 8px;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  @media (min-width: 550px) {
    padding: 16px;
  }

  @media (min-width: 670px) {
    flex: 3;
  }

  @media (min-width: 800px) {
    padding: 24px;
  }

  @media (min-width: 990px) {
    padding: 16px;
  }

  @media (min-width: 1320px) {
    padding: 24px;
  }
`

const ListingCardDetails = styled.div`
  overflow: hidden;
  word-wrap: break-word;
  padding-left: 0;
  padding-bottom: 0;
`

const ListingCardSortingRow = styled.div`
  clear: both;
  display: flex;
  flex: 1;
  align-items: top;
  overflow: hidden;

  @media (min-width: 550px) {
    clear: none;
  }
`

const ListingDescription = styled.div`
  height: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

const ListingHeading = styled.h2`
  font-size: 24px;
  color: ${hemneoColors.B2};
  line-height: 1.5;
`

const ListingCardInfo = ({ listing }) => (
  <ListingCardInfoContainer>
    <ListingCardDetails>
      <ListingHeading>
        {listing.streetAddress || "Gatuadress saknas"}
      </ListingHeading>
    </ListingCardDetails>
    <ListingCardSortingRow>
      <ListingDescription>{listing.description}</ListingDescription>
    </ListingCardSortingRow>
  </ListingCardInfoContainer>
)

export default ListingCardInfo
