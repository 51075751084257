import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ListingsList from "../components/ListingsList"

const listings = [
  {id: "1", thumbnail: { url: "https://bilder.hemnet.se/images/results_list/c0/46/c0466c7f1c4d5eb78d897d9dda728057.jpg"}, streetAddress: "Fantastiskt läge, Spanien", description: "Vi har nöjet att erbjuda denna bostad med 2 sovrum i en mycket central och strandnära urbanisation i eftertraktade Albir. Terrassen har integrerats i vardagsrummet och kan nyttjas som uteplats eller vardagsrum efter önskemål. I föreningen finns en gemensam pool och Medelhavet ligger endast 75 meter bort."},
  {id: "2", thumbnail: { url: "https://bilder.hemnet.se/images/results_list/45/9a/459aad9457119b2dd46316cbd3070a56.jpg"}, streetAddress: "Nära till havet, Spanien", description: "Prisvärd lägenhet i Playa Honda med gångavstånd till stranden. Här har du ett sovrum, ett badrum, vardagsrum med direkt anslutning till balkongen. Med tillgång till ett stort och härligt poolområde, garage och tennis."},
  {id: "3", thumbnail: { url: "https://bilder.hemnet.se/images/results_list/60/d2/60d220ee975534e8dfc79ccf4cf6d12a.jpg"}, askingPriceAmount: 100000, streetAddress: "Super Bra Erbjudande Stor Villa Pool Husalf, Spanien", description: "Denna underbara villa med 6 sovrum och 4 badrum är belägen nära Marbella centrum. Välskött och härliga detaljer i hela huset. Villan är byggd i 3 plan och erbjuder stora täckta terrasser, fullt utrustat kök och en härlig trädgård med privat pool. Hör av er för mer information."}
];

const UtlandPage = () => (
  <Layout>
    <SEO title="Utland" />
    <ListingsList title="Bostäder till salu utomlands" listings={listings} />
    <p>Vi arbetar ständigt med att höja kvaliteten på våra bostadsannonser.</p>
  </Layout>
)

export default UtlandPage
